<template>
  <div class="container px-2 mx-auto dark:bg-gray-900">
    <loading :active.sync="isLoading"
        :can-cancel="true"
        :on-cancel="onCancel"
        :is-full-page="fullPage">
    </loading>
    <div class="flex flex-row space-x-6 rounded-t-xl">
      <div class="flex items-center justify-start">
        <h2
          class="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200 text-left"
        >
          Plan de Cuenta
        </h2>
      </div>
      <div class="flex items-center justify-end text-xl">
        <!-- <span v-if="reloading === 3" class="mr-6">
          <button
            v-if="activeRequest2"
            title="Recargar Listado"
            class="py-2 px-2 font-medium leading-5 text-white transition-colors duration-150 bg-blue-600 border border-transparent rounded-md hover:bg-blue-500 focus:outline-none focus:shadow-outline-purple"
          >
            <i class="icon-arrows-cw animate-pulse mr-2" aria-hidden="true"></i
            >Actualizando Saldos
          </button>
          <button
            v-else
            v-on:click="updateAccountAmount()"
            title="Recargar Listado"
            class="py-2 px-2 font-medium leading-5 text-white transition-colors duration-150 bg-blue-600 border border-transparent rounded-md hover:bg-blue-500 focus:outline-none focus:shadow-outline-purple"
          >
            <i class="icon-arrows-cw" aria-hidden="true"></i>
          </button>
        </span>
        <span> -->
          <!-- <button
            v-if="activeRequest"
            title="Recargar Listado Rápido"
            class="py-2 px-2 font-medium leading-5 text-white transition-colors duration-150 bg-blue-600 border border-transparent rounded-md hover:bg-blue-500 focus:outline-none focus:shadow-outline-purple"
          >
            <i class="icon-arrows-cw animate-pulse" aria-hidden="true"></i>
          </button>
          <button
            v-else
            v-on:click="getUpdateGeneralLedger()"
            title="Recargar Listado Rápido"
            class="py-2 px-2 font-medium leading-5 text-white transition-colors duration-150 bg-blue-600 border border-transparent rounded-md hover:bg-blue-500 focus:outline-none focus:shadow-outline-purple"
          >
            <i class="icon-arrows-cw" aria-hidden="true"></i>
          </button> -->
          <button
          title="Exportar Tabla"
            v-on:click="downAcc()"
            class="py-2 px-2 mr-6 font-medium leading-5 text-white transition-colors duration-150 bg-green-600 border border-transparent rounded-md active:bg-blue-600 hover:bg-green-400 focus:outline-none focus:shadow-outline-purple"
          >
            <i class="icon-download" aria-hidden="true"></i>
          </button>
        <!-- </span> -->
      </div>
    </div>

    <nav class="sticky container mx-auto w-full top-0">
      <div
        class="flex flex-row flex-shrink-0 items-center justify-start shrink-0 text-sm font-bold uppercase rounded-t-lg bg-gradient-to-t from-blue-600 to-blue-500 text-white"
      >
        <div
          class="flex items-center justify-center w-12 h-10 px-1"
        >
          <span>N.</span>
        </div>
        <div class="flex items-center w-28 h-10 px-1">
          <span>Cuenta</span>
        </div>
        <div class="flex items-center w-96 h-10 px-1">
          <span>Descripción</span>
        </div>
        <!-- <div class="flex items-center flex-1 h-10 px-1"><span>Presupuesto</span></div> -->
        <div
          class="flex items-center flex-1 w-28 h-10 px-1"
        >
          <span>Debe</span>
        </div>
        <div
          class="flex items-center flex-1 h-10 px-1"
        >
          <span>Haber</span>
        </div>
        <div
          class="flex items-center flex-1 h-10 px-1"
        >
          <span>Saldo</span>
        </div>
        <div
          class="flex items-center justify-center w-10 h-10 px-1"
        >
          <span>Movim.</span>
        </div>
        <div
          class="flex items-center justify-center w-10 h-10 px-1"
        >
          <span>N.</span>
        </div>
        <div
          class="flex items-center justify-center w-28 h-10 px-1"
        >
          <span>Acciones</span>
        </div>
      </div>
    </nav>

    <div class="mt-0">
      <div class="w-full overflow-hidden shadow-xs">
        <div class="w-full overflow-x-auto">
          <!-- para generar plan de cuenta a futuro -->
          <div v-if="objAccount.length === 0" class="table-auto">
            <div
              class="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800"
            >
              <th class="px-2 py-3 w-full">
                <button @click="GenerateGeneralLeger(user.companyId)">
                  Plan De Cuenta
                </button>
              </th>
            </div>
          </div>

          <div class="overflow-hidden border-l-2 border-r-2">
            <div
              class="flex flex-shrink-0 justify-start text-sm hover:bg-blue-100 duration-150"
              v-for="(item, index) in objAccount"
              :key="index"
            >
              <div
                class="flex items-center pl-3 w-12 h-6 px-1 border-b border-gray-300"
              >
                <span>{{ index + 1 }}</span>
              </div>
              <div
                class="flex items-center w-28 h-6 px-1 border-b border-gray-300"
              >
                <span>{{ item.accountCode }}</span>
              </div>
              <div
                class="flex items-center text-left w-96 h-6 px-1 border-b border-gray-300 flex-wrap overflow-y-auto"
              >
                <button
                  v-if="editMode === index"
                  v-on:click="updateAcc(index, item)"
                  class="px-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-green-400 border border-transparent rounded-l-md active:bg-blue-600 hover:bg-green-500 focus:outline-none focus:shadow-outline-purple"
                >
                  <i class="icon-ok" aria-hidden="true"></i>
                </button>
                <button
                  v-if="editMode === index"
                  v-on:click="cancel(index, item)"
                  class="px-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-red-400 border border-transparent rounded-0 active:bg-blue-600 hover:bg-red-500 focus:outline-none focus:shadow-outline-purple"
                >
                  <i class="icon-cancel" aria-hidden="true"></i>
                </button>
                <input
                  v-if="editMode === index"
                  type="text"
                  v-model="item.accountName"
                  class="w-72 border-2 px-2 border-gray-300 rounded-r-md md:w-50"
                />
                <span v-else>{{ item.accountName }} </span>
              </div>
              <div
                class="flex items-center flex-1 h-6 px-1 border-b border-gray-300"
              >
                <span>{{
                  item.debit != 0
                    ? formatNumber(item.debit, ",", "$", true)
                    : " "
                }}</span>
              </div>
              <div
                class="flex items-center flex-1 h-6 px-1 border-b border-gray-300"
              >
                <span>{{
                  item.credit != 0
                    ? formatNumber(item.credit, ",", "$", true)
                    : " "
                }}</span>
              </div>
              <div
                class="flex items-center flex-1 h-6 px-1 border-b border-gray-300"
              >
                <span v-if="item.balance != 0">

                  
                  {{
                    item.balance > 0
                      ? formatNumber(item.balance, ",", "$", true)
                      : `( ${formatNumber(item.balance * -1, ",", "$", true)} )`
                  }}
                </span>
              </div>
              <div
                class="flex items-center justify-center w-10 h-6 px-1 border-b border-gray-300"
              >
                <span>{{ item.isMovementAccount }}</span>
              </div>
              <div
                class="flex items-center justify-center w-10 h-6 px-1 border-b border-gray-300"
              >
                <span>{{ item.accountNature }}</span>
              </div>
              <div
                class="flex items-center justify-center w-28 h-6 px-1 border-b border-gray-300"
              >
                <span>
                  <a
                    v-on:click="editAcc(index)"
                    class="px-1 py-1 text-sm font-medium leading-5 text-blue-500 hover:text-white transition-colors duration-150 border border-transparent rounded-md active:bg-blue-600 hover:bg-blue-500 focus:outline-none focus:shadow-outline-purple"
                    ><i class="icon-edit" aria-hidden="true"></i
                  ></a>
                </span>
                <span v-if="item.accountGroup === 4 || item.accountGroup === 5">
                  <a
                    v-on:click="showAddBudget(item)"
                    class="px-1 py-1 text-sm font-medium leading-5 text-blue-500 hover:text-white transition-colors duration-150 border border-transparent rounded-md active:bg-blue-600 hover:bg-blue-500 focus:outline-none focus:shadow-outline-purple"
                    ><i class="icon-attach" aria-hidden="true"></i
                  ></a>
                </span>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
    <modal name="modalBudget">
      <div class="p-4 semibold text-center">
        {{ accountCode }} 
        {{ accountNameCode }}
        <!-- <span class="text-blue-700 font-bold"> modificación </span> del asiento -->
      </div>
      <div class="p-2">
        <input type="date" v-model="transactionDate" class="border-2 w-40 border-gray-300 rounded">
        <Multiselect 
          v-model="auxiliary" 
          tag-placeholder="Add" 
          placeholder="Aux" 
          label="name" track-by="code" 
          :options="ObjAuxiliary" 
          :multiple="false" 
          :taggable="true" 
          @tag="addTag"
        ></Multiselect>
      </div>
      <div class=" ">
        <input
          type="text"
          v-model="amount"
          class="w-40 border-2 px-2 border-gray-300 rounded md:w-50"
          placeholder="100.50"
        />
      </div>
      <div class="text-sm w-full text-right p-3">
        <button
          @click="addbudget()"
          class="ml-2 px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-green-600 border border-transparent rounded-lg active:bg-blue-500 hover:bg-green-700 focus:outline-none focus:shadow-outline-purple"
        >
          Guardar
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import Axios from "axios";
import moment from 'moment'
// import Multiselect from 'vue-multiselect'
// import router from '@/router'
// import Swal from 'sweetalert2'
import { mapState } from "vuex";
import { jsPDF } from "jspdf";
import Multiselect from 'vue-multiselect'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
  name: "ContentGeneralLedger",
  components: {
    Loading,
    Multiselect,
  },
  props: {},
  data() {
    return {
      activeRequest2: false,
      activeRequest: false,
      editMode: -1,
      loading: false,
      URL: process.env.VUE_APP_URL_ACC,
      URLAdm: process.env.VUE_APP_URL,
      error: false,
      objGerenalLeger: [],
      dateFrom: "",
      dateTo: "",
      account: "",
      amount: 0,
      numberAccTrans: "",
      objAccount: [],
      mutaObjAccount: [],
      // comboObjAccount:[],
      reloading: 0,
      auxiliary: null,
      account_id: null,
      accountNameCode:"",
      accountCode:"",
      accountNature:"",
      auxiliaryType:"contract",
      ObjAuxiliary:[],
      transactionDate:moment().format('YYYY-MM-DD'),
      isLoading: false,
			fullPage: true,
      
    };
  },
  computed: {
    ...mapState(["user","access_token", "year"]),
  },
  mounted() {
    this.getGeneralLedger();
    // this.updateAccountAmount();
    this.getAuxiliary();
  },
  methods: {
    onCancel() {
      console.log('User cancelled the loader.')
    },
    showAddBudget(value){
      console.log(value)   
      this.accountCode = value.accountCode
      this.account_id = value.id
      this.accountNameCode = value.accountName
      this.accountCopy = value.accountCopy,
      this.accountNature = value.accountNature,
      this.$modal.show("modalBudget");
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.options.push(tag);
      this.transaction.push(tag);
    },
    async getAuxiliary() { //
				if (this.auxiliaryType === 'contract') {
					const URL = `${this.URLAdm}v1/contract`
					const fetchConfig = {
						headers: {
							Accept : 'application/json',
							"Content-Type": 'application/json',
							Authorization : `Bearer ${this.access_token.replace(/['"]+/g, '')}`	
						}
					}
					let params = {
						companyId: this.user.companyId
					}
					// console.log(params)
					// try {
					let rsAccount = await Axios.post(URL, params, fetchConfig)
					console.log(rsAccount)
					// return
					const result = rsAccount.data;
					// console.log(result)
					this.ObjAuxiliary = result.map(item => {
						// console.log(item.client.clientName)
							return { name: item.contractNumber + ' - ' + item.siteAddress, code: item.contractNumber}
						// return { text: item.accountName, value: item.id}
					})
				}
			},
    formatNumber(number, separator = ",", symbol = "$", showSymbol = false) {
      number = parseFloat(number);
      number = number.toFixed(2);
      if (separator == ",") {
        let montoNuevo = number
          .toString()
          .replace(/\D/g, "")
          .replace(/([0-9])([0-9]{2})$/, "$1.$2")
          .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ",");
        if (showSymbol) {
          return `${symbol}${montoNuevo}`;
        } else {
          return `${montoNuevo}`;
        }
      } else {
        let montoNuevo = number
          .toString()
          .replace(/\D/g, "")
          .replace(/([0-9])([0-9]{2})$/, "$1,$2")
          .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
        if (showSymbol) {
          return `${symbol}${montoNuevo}`;
        } else {
          return `${montoNuevo}`;
        }
      }
    },
    editAcc(index) {
      this.editMode = index;
    },
    async updateAcc(index, item) {
      let url = `${this.URL}updateAccount`;
      const fetchConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          // Authorization : `Bearer ${this.access_token.replace(/['"]+/g, '')}`
        },
      };
      const params = {
        id: item.id,
        accountName: item.accountName,
        budget: item.budget,
      };
      let res = await Axios.patch(url, params, fetchConfig);
      // console.log(res)
      if (res.statusText === "OK") {
        this.editMode = -1;
        return;
      } else {
        this.getGeneralLedger();
      }
    },
    cancel(index, val) {
      // console.log(index)
      this.editMode = -1;
      this.getGeneralLedger();
    },
    async getGeneralLedger() {
      this.activeRequest = !this.activeRequest;
      const URL = `${this.URL}showAllCompanyAccountsFlatMode`;
      const fetchConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          // Authorization : `Bearer ${this.access_token.replace(/['"]+/g, '')}`
        },
      };
      let params = {
        company_id: this.user.companyId,
        company: this.user.companyId,
        year: this.year,
      };
      try {
        const rsAccount = await Axios.post(URL, params, fetchConfig);
        this.objAccount = rsAccount.data.data;
        console.log(this.objAccount);
        // this.comboObjAccount = rsAccount.data.data.map(item => {
        // 	return { name: item.accountCode + ' - ' + item.accountName, code: item.id}
        // })
        if (rsAccount.statusText === "OK") {
          this.reloading = 3;
          this.activeRequest = !this.activeRequest;
        }
      } catch (error) {
        if (error.message == "Request failed with status code 422") {
          this.reloading = 1;
          this.activeRequest = !this.activeRequest;
        }
        console.log(error.name); //
        console.log(error.message); //
        console.log(error.foo); //
        console.log(error.stack); //
      }
    },
    async addbudget() {
      this.activeRequest = !this.activeRequest;
      const URL = `${this.URL}addAccountBudget`;
      const fetchConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          // Authorization : `Bearer ${this.access_token.replace(/['"]+/g, '')}`
        },
      };    
      let params = {
              account_id: this.account_id,
              accountCode: this.accountCode,
              accountCopy:  this.accountCopy.toString(),
              description: this.accountNameCode,
              company_id: this.user.companyId,
              user_id: this.user.userId,
              user_name: this.user.fullName,
              auxiliary: this.auxiliary.code,
              auxiliary_type: "budget",
              budget: this.amount,
              transactionDate: this.transactionDate,
              accountNature: this.accountNature
      }
      console.log(params)
      try {
        const rsAccount = await Axios.post(URL, params, fetchConfig);
        // this.objAccount = rsAccount.data.data;
        console.log(rsAccount);
        // this.comboObjAccount = rsAccount.data.data.map(item => {
        // 	return { name: item.accountCode + ' - ' + item.accountName, code: item.id}
        // })
        if (rsAccount.statusText === "OK") {
            this.account_id = null,
            this.accountCode = '',
            this.accountCopy = '',
            this.accountNameCode = '',
            this.user.companyId,
            this.user.userId,
            this.user.fullName,
            this.auxiliary = null,
            this.auxiliary_type = '',
            this.accountNature = '',
            this.amount = 0,
            this.transactionDate = moment().format('YYYY-MM-DD')
            this.$modal.hide("modalBudget")
        }
      } catch (error) {
        if (error.message == "Request failed with status code 422") {
        }
        console.log(error.name); //
        console.log(error.message); //
        console.log(error.foo); //
        console.log(error.stack); //
      }
      
    },
    async downAcc() {
      this.isLoading = true
				setTimeout(() => {
					this.isLoading = false
				
      let doc = new jsPDF("p", "pt", "letter");
      let lineaY = 100;
      let lineaX = 70;
      let positionCursor = 100;
      let positionCompany = (this.user.companyName.length / 2) * 6;
      positionCompany = parseInt(positionCompany);
      positionCompany = 240 - positionCompany;
      doc.setFontSize(10);
      doc.setFont("helvetica", "bold");
      doc.text("ACCOUNT", lineaX, lineaY - 15); //descripcion
      doc.text("DESCRIPTION", lineaX + 110, lineaY - 15); //descripcion
      doc.text("GENERAL LEDGER", lineaX + 191, lineaY - 70); //descripcion
      doc.text(
        this.user.companyName,
        lineaX + positionCompany - 6,
        lineaY - 55
      ); //descripcion
      // console.log(positionCompany);
      // return
      let pageCount = doc.internal.getNumberOfPages();
      // // let currentPage
      // currentPage = doc.internal.getCurrentPageInfo().pageNumber; // página actual
      doc.text("Page", lineaX + 470, lineaY - 70); //descripcion
      doc.text(pageCount + "", lineaX + 500, lineaY - 70); //descripcion
      this.objAccount.forEach((element) => {
        pageCount = doc.internal.getNumberOfPages();
        doc.setFontSize(7);
        doc.setFont("helvetica", "bold");
        // console.log(element.accountCode)
        if (positionCursor > 730) {
          // currentPage = doc.internal.getCurrentPageInfo().pageNumber; // página actual
          lineaY = 100;
          positionCursor = 100;
          doc.addPage();
          doc.setFontSize(10);
          doc.text("GENERAL LEDGER", lineaX + 191, lineaY - 70); //descripcion
          doc.text(
            this.user.companyName,
            lineaX + positionCompany - 6,
            lineaY - 55
          ); //descripcion
          doc.setFont("helvetica", "bold");
          doc.text("ACCOUNT", lineaX, lineaY - 15); //descripcion
          doc.text("DESCRIPTION", lineaX + 110, lineaY - 15); //descripcion
          doc.text("Page", lineaX + 470, lineaY - 70); //descripcion
          doc.text(pageCount + 1 + "", lineaX + 500, lineaY - 70); //descripcion
          doc.setFontSize(7);
          doc.setFont("helvetica", "bold");
        }
        doc.text(element.accountCode, lineaX, lineaY); //ACCOUNT
        doc.text(element.accountName, lineaX + 110, lineaY); //descripcion
        lineaY = lineaY + 10;
        positionCursor = positionCursor + 10;
      });
      this.isLoading = false
      // console.log(pageCount)
      doc.save("GENERAL LEDGER - " + this.user.companyName + ".pdf");
    }, 50)
  },
    async updateAccountAmount() {
      this.isLoading = true
				setTimeout(() => {
					this.isLoading = false
				}, 2000)
      this.activeRequest2 = !this.activeRequest2;
      const URL = `${this.URL}calculateAllAccountsBalances`;
      const fetchConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          // Authorization : `Bearer ${this.access_token.replace(/['"]+/g, '')}`
        },
      };
      let params = {
        company_id: this.user.companyId,
      };
      try {
        const rsAccount = await Axios.post(URL, params, fetchConfig);
        // console.log(rsAccount.data.data.message);
        if (rsAccount.statusText === "OK") {
          alert(rsAccount.data.data.message);
          this.activeRequest2 = !this.activeRequest2;
          this.getGeneralLedger();
        }
      } catch (error) {
        console.log(error.name); //
        console.log(error.message); //
        console.log(error.stack); //
      }
      this.isLoading = false
      // try { reloading
    },
    async getUpdateGeneralLedger() {
      this.isLoading = true
				setTimeout(() => {
					this.isLoading = false
				}, 2000)
      this.getGeneralLedger();
      // console.log('sss')
      this.isLoading = false
    },
    async GenerateGeneralLeger(companyId) {
      // console.log(companyId);
      const URL = `${this.URL}showAllCompanyAccountsHierarchicalMode/${8}`;
      const fetchConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          // Authorization : `Bearer ${this.access_token.replace(/['"]+/g, '')}`
        },
      };
      // try { reloading
      const rsAccount = await Axios.get(URL, fetchConfig);
      this.objAccount = rsAccount.data.data;
      // console.log(rsAccount);
      // this.comboObjAccount = rsAccount
    },
  },
};
</script>