<template>
	<div v-if="hasPermission">
		<ContentGeneralLedger />
	</div>
</template>

<script>
// @ is an alias to /src

// import Loans from '@/components/content/content-loans/ContentLoans.vue'
import ContentGeneralLedger from '@/components/content/content-acc/ContentGeneralLedger.vue'
import { getPermissions } from '../components/util/liblist'

export default {
	name: 'GeneralLedger',
	components: {
		ContentGeneralLedger,
	},
	data() {
		return {
			'hasPermission': false,
		}
	},
	mounted() {
		if (getPermissions('accounting.account.index')) {
			this.hasPermission = true
		}
	},
	computed: {

	},
	methods: {

	},
}
</script>
